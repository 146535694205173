import { useGeneral } from '@utils/general';

interface ModalOption {
  closeOnBackdropClick?: boolean;
  hideCloseButton?: boolean;
}

export const useModal = (options: ModalOption = {closeOnBackdropClick: true, hideCloseButton: false}) => {
  // Get the generic modal dialog found in the DOM
  const dialog = document.querySelector("dialog.modal") as HTMLDialogElement;

  const showModal = (): Promise<void> => {
    const { trapFocus } = useGeneral();

    if (options.hideCloseButton) {
      (dialog.querySelector('.modal__close') as HTMLElement).hidden = true;
    }

    return new Promise((resolve) => {
      dialog.showModal();

      // Wait for the dialog to open before showing the animation
      setTimeout(() => {
        dialog.classList.add('modal--open');
        document.body.classList.add('has-full-screen-modal');
        dialog.querySelector('.modal__close')?.addEventListener('click', closeModal);

        if (options.closeOnBackdropClick) {
          dialog.addEventListener('click', closeModalOnBackdropClick);
        }

        dialog.dispatchEvent(new Event('showModal'));

        document.removeEventListener(`keydown`, (event) => {
          const focusableElements = 'button, [href], input, select, textarea, iframe, [tabindex]:not([tabindex="-1"])';

          trapFocus(event, dialog.querySelectorAll(focusableElements));
        });

        resolve();
      }, 200);
    });
  };

  const closeModal = (): void => {
    dialog.classList.remove('modal--open');

    // Wait for the animation to finish before closing the dialog
    setTimeout(function() {
      dialog.close();
      document.body.classList.remove('has-full-screen-modal');
      dialog.querySelector('.modal__close')?.removeEventListener('click', closeModal);

      if (options.closeOnBackdropClick) {
        dialog.removeEventListener('click', closeModalOnBackdropClick);
      }
      
      dialog.dispatchEvent(new Event('closeModal'));
    }, 200);
  };

  const setModalContent = (content: string): void => {
    const contentContainer = dialog.querySelector('.modal__content');
    if (contentContainer) {
      contentContainer.innerHTML = content;
    } else {
      console.error('Modal content container not found');
    }
  };

  const closeModalOnBackdropClick = (event: MouseEvent): void => {
    const rect = dialog.getBoundingClientRect();

    if (
      rect.left > event.clientX ||
      rect.right < event.clientX ||
      rect.top > event.clientY ||
      rect.bottom < event.clientY
    ) {
      closeModal();
    }
  };

  return {
    showModal,
    closeModal,
    setModalContent,
  };
}
