import { useModal } from '@features/Shared/Modal/_js/modal';
import { debounce } from '@utils/debounce';
import { useGeneral } from '@utils/general';

export const useHeader = () => {
  let modalOptions = {
    closeOnBackdropClick: false,
    hideCloseButton: true
  };

  const {setCookie, getCookie} = useGeneral();
  const {showModal, setModalContent} = useModal(modalOptions);
  const template: string = document.getElementById('modal-language-selector')?.innerHTML as string;
  
  // Redirect to search page
  const redirectToSearchPage = () => {
    const searchField = document.querySelector('.header__search-field') as HTMLInputElement;
    if (searchField.value) {
      let container = searchField.closest('.header__search-field-container') as HTMLElement;
      let searchPageUrl = container?.dataset.searchpageurl;

      window.location.href = `${searchPageUrl}?q=${searchField.value}`;
    }
  };

  // Open language selector modal
  const openLanguageSelectorModal = () => {
    document.querySelector('body')?.classList.add('has-full-screen-modal');

    showModal().then(() => {
      setModalContent(template);
      
      // If modal is already initialized, do not set up event listeners again
      if (document.querySelector('.language-selector-modal--initialized')) {
        return;
      }

      // Back to continents list view
      document.querySelector('.language-selector-modal__back-button')?.addEventListener('click', () => {
        document.querySelectorAll<HTMLDivElement>('.language-selector-modal__continent')?.forEach(continent => {
          continent.classList.remove('language-selector-modal__continent--active');
          continent.classList.remove('language-selector-modal__continent--hidden');

          // Remove continent active class from modal
          document.querySelector('.language-selector-modal')?.classList.remove('language-selector-modal--continent-active');
        });
      });

      // Open continent view
      document.querySelectorAll('.language-selector-modal__continent-heading-button')?.forEach((element) => {
        element?.addEventListener('click', (event) => {
          const currentContinent = (event.target as HTMLElement).closest('.language-selector-modal__continent') as HTMLElement;

          // Show current continent
          currentContinent.classList.toggle('language-selector-modal__continent--active');

          // Add continent active class to modal to be able to hide other elements
          element.closest('.language-selector-modal')?.classList.toggle('language-selector-modal--continent-active');

          // Hide all other continents
          document.querySelectorAll('.language-selector-modal__continent')?.forEach((continent) => {
            if (!continent.classList.contains('language-selector-modal__continent--active')) {
              continent.classList.add('language-selector-modal__continent--hidden');
            }
          });
        });
      });

      // Set cookie on language selection
      document.querySelectorAll<HTMLAnchorElement>(".language-selector-modal__link").forEach((link) => {
        link.addEventListener('click', (event) => {
          if (getCookie("country-picker-cookie") != link.href) {
            setCookie("country-picker-cookie", link.href, 365);
          }
        });
      });
      
      // Add initialized class to modal
      document.querySelector('.language-selector-modal')?.classList.add('language-selector-modal--initialized');
    });
  };

  // Click event on the search button and close button
  document.querySelectorAll('.search-button-toggle, .header__search-field-close')?.forEach((element) => {
    element?.addEventListener('click', () => {
      document.querySelector('.header__search-field-container')?.classList.toggle('header__search-field-container--active');
      (document.querySelector('.header__search-field') as HTMLElement)?.focus();
    });
  });

  // Click event on the search submit button
  document.querySelector('.header__search-field-submit')?.addEventListener('click', () => {
    redirectToSearchPage();
  });

  // Enter event on the search field
  document.querySelector('.header__search-field')?.addEventListener('keypress', (event) => {
    if ((event as KeyboardEvent).key === 'Enter') {
      redirectToSearchPage();
    }
  });

  // Open language selector modal on click
  document.querySelectorAll('.header__language-select, .menu__language-select-button').forEach((element) => {
    element?.addEventListener('click', () => {
      openLanguageSelectorModal();
    });
  });

  // Open language selector modal on page load if no cookie is set
  if (window.location.pathname === '/' && getCookie("country-picker-cookie") == null) {
    openLanguageSelectorModal();
  }
  
  // Remove continent active class from modal on resize
  window.addEventListener('resize', debounce(() => {
    if (window.innerWidth > 1200) {
      document.querySelector('.language-selector-modal--continent-active')?.classList.remove('language-selector-modal--continent-active');
      document.querySelector('.language-selector-modal__continent--active')?.classList.remove('language-selector-modal__continent--active');
      document.querySelectorAll('.language-selector-modal__continent--hidden')?.forEach((element) => {
        element.classList.remove('language-selector-modal__continent--hidden');
      });
    }
  }, 200));

  // Redirect to saved cookie country if set
  if (window.location.pathname == '/' && getCookie("country-picker-cookie") != null && getCookie("country-picker-cookie") != "/") {
    window.location.href = getCookie("country-picker-cookie")!;
  }
};

useHeader();